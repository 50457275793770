<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
    }" :visible="visible" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Alterar número</h5>
        </template>

        <!-- <Loader v-if="isLoading" /> -->
        <div class="content">
          <p class="change-number">Altere o seu número de telefone</p>
          <div class="inputs">
            <Dropdown
                v-model="postalCode"
                class="std-top upper"
                :options="countryList"
                optionLabel="name"
                placeholder="Selecione o país"
                @change="updateCountryCode"
            />

            <!-- INPUT TO MASK NUMBER -->
            <InputMask
                v-if="postalCode.code === '+55'"
                v-model="phone"
                class="std-bottom"
                mask="( 99 ) 9 9999-9999"
                placeholder="( xx ) x xxxx - xxxx"
                @change="updateModel"
            />
            <InputMask
                v-else-if="postalCode.code === '+1'"
                v-model="phone"
                class="std-bottom"
                mask="( 999 ) 99999-9999"
                placeholder="( xxx ) xxxxx - xxxx"
                @change="updateModel"
            />
            <InputText v-else class="std-bottom" v-model="phone" placeholder="Telefone" disabled />
            <p class="p-invalid" v-if="msgError">{{msgError}}</p>
            <!-- END INPUT TO MASK NUMBER -->
            <div class="flex justify-content-center align-items-center h-full mt-24">
                <Button label="Salvar" class="addService-button" @click="submit" />
            </div>
          </div>
        </div>
    </Dialog>
</template>
  
<script>
import { ref, onMounted, watch, reactive, computed } from "vue";
// import Loader from "./Loader";
import { UpdatePhone } from "../actions";

const countryList = [
    { name: "Brasil +55", code: "+55" },
    { name: "Estados Unidos +1", code: "+1" },
];

export default {
    props: ["visible",],
    emits: ["update:visible", "retrySendCode"],
    components: {
    },
    setup(props, ctx) {
        const isLoading = ref(true);
        const mounted = ref(false);
        const postalCode = ref({name: "", code: ""});
        const phone = ref("");

        const msgError = ref("");
        const number_used = ref("");

        const close = () => {
            ctx.emit("update:visible", false);
            clear();
        }

        const onUpdate = (val) => {
            (!val) && ctx.emit("update:visible", val);
        }

        const submit = async () => {
            const oldPhone = localStorage.getItem("phone").split(" ").join("");
            const newPhone = postalCode.value.code + phone.value.replace(/[^0-9]/g, "");

            if(phone.value){
                if(oldPhone !== newPhone){
                    const res = await UpdatePhone({
                        code: postalCode.value.code,
                        phone: phone.value.replace(/[^0-9]/g, "")
                    })

                    if(res.success){
                        ctx.emit("retrySendCode", {
                            code: postalCode.value.code,
                            phone: phone.value.replace(/[^0-9]/g, "")
                        });
                        close();
                        console.log(res.data);
                    }else{
                        const data = JSON.parse(res.data)

                        if('telefone' in data){
                            if(data.telefone.length){
                                msgError.value = data.telefone[0]
                            }
                        }
                    }
                    
                }
            }
        }

        const clear = () => {
            phone.value = "";
            postalCode.value = {name: "", code: ""}
            isLoading.value = true;
            mounted.value = false;
        }

        const updateCountryCode = (e) => {
            // console.log(e);
            phone.value = "";
        }

        const updateModel = (e) => {
            // console.log(e);
        }
        
        onMounted(async () => {
            mounted.value = await true;
            isLoading.value = await false;
        });

        return {
            isLoading,
            close,
            onUpdate,
            submit,
            updateCountryCode,
            postalCode,
            countryList,
            phone,
            updateModel,
            msgError,
            number_used
        };
    },
};
</script>
  
<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 1.5rem;
    .change-number {
        font-size: 14px;
        line-height: 22px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: #828282;
        text-align: center;
        max-width: 40ch;
        margin: 0 auto;
    }
    .inputs{
        display: flex;
        flex-direction: column;
        margin-top: 16px;
    }
}

::v-deep(.p-dropdown-label) {
    display: flex;
    align-items: center;
    color: #2D313D;
    padding: 0 16px;
    height: 48px;
    
    &::placeholder{
        color: #BDBDBD;
    }
}

::v-deep(.p-dropdown) {
    display: flex;
    align-items: center;
    color: #2D313D;
    height: 50px;
    font-weight: 600 !important;
    &::placeholder{
        color: #BDBDBD;
    }
}

::v-deep(.std-top) {
    height: 50px;
}
::v-deep(.std-bottom) {
    height: 50px;
    padding: 0 16px;
    opacity: 1;
    &:disabled{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}
::v-deep(.std-input-down) {
    height: 50px;
    &::placeholder{
        border-color: #FF6A33 !important;
    }
}
::v-deep(.p-inputtext) {
    height: 50px;
    /* border-bottom: 1px solid white; */
    &:disabled{
        height: 50px;
        background: white;
        cursor: not-allowed;
    } 
}
::v-deep(button) {
    width: max-content;
    height: 40px;
    padding: 0 80px;
}
</style>
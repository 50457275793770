import patientAccountService from "../../../core/services/patientAcountService";
import { 
    SendPhoneConfirmationRequest,
    VerifyPhoneRequest,
    UpdatePhoneRequest,
    EmptyRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const PhoneConfirmation = async (phone) => {
    try {
        const req = new SendPhoneConfirmationRequest();
        req.setPhone(phone);

        const res = await patientAccountService.sendPhoneConfirmation(req);

        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const UpdatePhone = async (obj) => {
    try {
        const req = new UpdatePhoneRequest();
        req.setCountrycode(obj.code);
        req.setPhone(obj.phone);

        const res = await patientAccountService.updatePhone(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}


const VerifyPhone = async (obj) => {
    try {
        const req = new VerifyPhoneRequest();
        req.setPhone(obj.phone);
        req.setCode(obj.code);

        const res = await patientAccountService.verifyPhone(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const VerifyToken = async () => {
    try {
        const req = new EmptyRequest();

        const res = await patientAccountService.verifyToken(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

export {
    PhoneConfirmation,
    UpdatePhone,
    VerifyPhone,
    VerifyToken,
}
<template>
    <div class="pagination">
        <div class="item" v-for="(el, index) in size" :class="[active(index)]">
            <div class="circle"  />
            <div class="bar" v-if="index+1 < size" />
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["value", "size",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        const active = (index) => {
            if(index < props.value){
                return 'ativo'
            }
            return ''
        };


        return {
            active,
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.pagination {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 16px 0;

    .item {
        display: flex;
        align-items: center;


        &:not(:first-child) {
            .circle{
                margin-left: -4px;
            }
        }

        .circle {
            width: 8px;
            height: 8px;
            border-radius: 8px;
            z-index: 2;
            border: 1px solid #F2F2F2;
            background: white;            
        }

        .bar {
            width: 24px;
            height: 4px;
            margin-left: -4px;
            background: #F2F2F2;
            z-index: 1;
        }
    }

    .ativo {
        .circle {
            background: #2F80ED !important;
        }

        .bar {
            background: rgba(47, 128, 237, 0.2);
        }
    }
}

// ::v-deep(.) {

// }
</style>